import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import VueCookie from "vue-cookie";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/userLogin",
    name: "userLogin",
    component: () => import("../views/userLogin"),
  },
  {
    path: "/selfData",
    name: "selfData",
    component: () => import("../views/selfData"),
  },
  {
    path: "/bankCardC",
    name: "bankCardC",
    component: () => import("../views/bankCardC"),
  },
  {
    path: "/bankCardP",
    name: "bankCardP",
    component: () => import("../views/bankCardP"),
  },
  {
    path: "/ordersC",
    name: "ordersC",
    component: () => import("../views/ordersC"),
  },
  {
    path: "/ordersP",
    name: "ordersP",
    component: () => import("../views/ordersP"),
  },
  {
    path: "/ordersAlp",
    name: "ordersAlp",
    component: () => import("../views/ordersAlP"),
  },
  {
    path: "/orderCInfo",
    name: "orderCInfo",
    component: () => import("../views/orderCInfo"),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("../views/withdraw"),
  },
  {
    path: "/deposit",
    name: "deposit",
    component: () => import("../views/deposit"),
  },
  {
    path: "/withdrawRecord",
    name: "withdrawRecord",
    component: () => import("../views/withdrawRecord"),
  },
  {
    path: "/depositRecord",
    name: "depositRecord",
    component: () => import("../views/depositRecord"),
  },
  {
    path: "/orderRecord",
    name: "orderRecord",
    component: () => import("../views/orderRecord"),
  },
  {
    path: "/orderPInfo",
    name: "orderPInfo",
    component: () => import("../views/orderPInfo"),
  },
  {
    path: "/record",
    name: "record",
    component: () => import("../views/record"),
  },
  {
    path: "/bulletin",
    name: "bulletin",
    component: () => import("../views/bulletin"),
  },
  {
    path: "/defaultSet",
    name: "defaultSet",
    component: () => import("../views/defaultSet"),
  },
  {
    path: "/myBank",
    name: "myBank",
    component: () => import("../views/myBank"),
  },
  {
    path: "/myTeam",
    name: "myTeam",
    component: () => import("../views/myTeam"),
  },
  {
    path: "/myReport",
    name: "myReport",
    component: () => import("../views/myTeam/myReport"),
  },
  {
    path: "/subList",
    name: "subList",
    component: () => import("../views/myTeam/subList"),
  },
  {
    path: "/teamReport",
    name: "teamReport",
    component: () => import("../views/myTeam/teamReport"),
  },
  {
    path: "/orderTimeout/:id",
    name: "orderTimeout",
    component: () => import("../views/orderTime"),
  },
  {
    path: "/createAcc",
    name: "createAcc",
    component: () => import("../views/createAcc"),
  },
  {
    path: "/orderCRecord",
    name: "orderCRecord",
    component: () => import("../views/orderCRecord"),
  },
  {
    path: "/orderPRecord",
    name: "orderPRecord",
    component: () => import("../views/orderPRecord"),
  },
  {
    path: "/notify",
    name: "notify",
    component: () => import("../views/notify"),
  },
  {
    path: "/checkCard",
    name: "checkCard",
    component: () => import("../views/checkCard"),
  },
  {
    path: "/invites",
    name: "invites",
    component: () => import("../views/invites"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register"),
  },
  {
    path: "/orderPRecordSend",
    name: "orderPRecordSend",
    component: () => import("../views/orderPRecordSend"),
  },
  {
    path: "/orderCecordSend",
    name: "orderCecordSend",
    component: () => import("../views/orderCecordSend"),
  },
];

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  VueCookie.set("prevPage", from.path);
  next();
});
export default router;
