import { Notify } from "vant";
import axios from "axios";
import VueCookie from "vue-cookie";
import store from "../../store/index";
import router from "../../router/index";

let params = new URL(document.location).searchParams;

let baseURL;

let domainTitleMap = {
  "pay.zhaofupay.com": "https://i.zhaofupay.com",
  "runn-f.test": "/", // 本機測試
  "runn-in.etmaer3.test": "/", // 本機測試
  "running.etmart2.test": "/", // 本機測試
  "running.etmart2.com": "/", // 測試機測試
  "running.etmart3.com": "/", // 測試機測試
  "43.139.102.188": "http://43.139.102.188", // 聚合支付
  "43.138.153.196": "http://43.138.153.196", // 海淘
  "122.152.225.41": "http://122.152.225.41", // 天運
  "119.91.195.29": "http://119.91.195.29", // Li
  "42.194.148.159": "http://42.194.148.159", // Li
  // "store.kms900.com": "https://888.kms900.com", // Li
};

baseURL = domainTitleMap[document.domain] || "https://888.ffb252.com";

axios.defaults.baseURL = baseURL;

axios.defaults.headers.common.Authorization =
  "Bearer " + VueCookie.get("token");
if (params.get("id") && params.get("token")) {
  axios.defaults.headers.common["id"] = params.get("id");
  axios.defaults.headers.common.Authorization = "Bearer " + params.get("token");
}

let request = [];
const denied = JSON.parse(localStorage.getItem("dlasdla"));
const codeList = {
  BINDFA: 10016,
};
axios.interceptors.request.use(
  (config) => {
    if (
      store.state.version &&
      store.state.currentVersion != store.state.version
    ) {
      Notify({
        duration: 0,
        message: "为了最新体验，请重新整理或重启应用。",
        type: "danger",
      });
      return false;
    }
    if (
      (VueCookie.get("dlasdla") && VueCookie.get("dlasdla") == "asdq123") ||
      (denied && denied.expires > new Date().getTime())
    ) {
      Notify({
        message: "拒绝访问-" + VueCookie.get("userIp"),
        type: "danger",
      });
      return false;
    }
    // if (config.url.indexOf("messages") != -1 && config.method == "get") {
    //   return config;
    // }
    // if (config.url.indexOf("read") != -1) {
    //   return config;
    // }
    store.dispatch("setWaitQuest", true);
    store.dispatch("setLoadding", true);
    request.push(config.url.split("?")[0]);
    return config;
  },
  () => {
    Notify({
      message: "网络异常,请联系客服",
      type: "danger",
    });
  }
);
axios.interceptors.response.use(
  (res) => {
    request.splice(request.indexOf(res.config.url.split("?")[0]), 1);
    if (request.length == 0) {
      store.dispatch("setReload", false);
      store.dispatch("setLoadding", false);
      store.dispatch("setWaitQuest", false);
    }
    if (res.data.code == codeList.BINDFA) {
      store.dispatch("bindFA", true);
    }
    if (res.data.code == 9999999) {
      var date = new Date();
      var min = date.getMinutes();

      fetch(`http://ip-api.com/json`)
        .then((res) => res.json())
        .then((data) => {
          VueCookie.set("userIp", data.query);
        });
      VueCookie.set("dlasdla", "asdq123", { expires: "10m" });
      localStorage.setItem(
        "dlasdla",
        JSON.stringify({
          have: true,
          expires: date.setMinutes(min + 10),
        })
      );
    }
    if (res.data.code == 10001) {
      if (store.state.selfData) {
        if (window.pusher) {
          window.pusher.unsubscribe(
            `private-App.User.${store.state.selfData.member_id}`
          );
        }
      }
      Notify({
        message: res.data.message,
        type: "danger",
      });
      axios.defaults.headers.common.Authorization = "";
      VueCookie.delete("token");
      if (router.history.current.name != "userLogin") {
        router.push("/userLogin");
      }
      return res;
    }
    if (res.data.code == 50000) {
      return res;
    }
    if (res.data.code != 0) {
      Notify({
        message: res.data.message,
        type: "danger",
      });
      return res;
    }
    return res;
  },
  (err) => {
    console.log(err.toJSON());
    // store.dispatch("setLoadding", false);
    // request.splice(request.indexOf(res.config.url.split("?")[0]), 1);
    setTimeout(() => {
      store.dispatch("setLoadding", false);
      request = [];
    }, 1500);
    if (!err.response) {
      fetch(`http://ip-api.com/json`)
        .then((res) => res.json())
        .then((data) => {
          Notify({
            showClose: true,
            message: `请先检查您的网络-${err.toJSON().config.url}-${
              data.query
            }\n${err.toJSON().message}`,
            type: "danger",
          });
        })
        .catch(() => {
          Notify({
            showClose: true,
            message: `请先检查您的网络-${err.toJSON().config.url}\n${
              err.toJSON().message
            }`,
            type: "danger",
          });
        });
      store.dispatch("setLoadding", false);
      return;
    }
    // if (err.response.status == 403) {
    //   var date = new Date();
    //   var min = date.getMinutes();
    //   VueCookie.set("dlasdla", "asdq123", { expires: "10m" });
    //   localStorage.setItem(
    //     "dlasdla",
    //     JSON.stringify({
    //       have: true,
    //       expires: date.setMinutes(min + 10),
    //     })
    //   );
    // }
    request.splice(request.indexOf(err.config.url.split("?")[0]), 1);
    if (request.length == 0) {
      store.dispatch("setReload", false);
      store.dispatch("setLoadding", false);
      store.dispatch("setWaitQuest", false);
    }
    if (err.response.status == 500) {
      Notify({
        showClose: true,
        message: "连线失败",
        type: "danger",
      });
    }
    if (err.response.status == 429) {
      Notify({
        showClose: true,
        message: "登入失败次数过多，锁定一小时！",
        type: "danger",
      });
    }
  }
);
