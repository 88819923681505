<template>
  <div v-show="isChatOpen">
    <!-- <van-dialog v-model="show" title="标题" show-cancel-button>
    </van-dialog> -->
    <div class="chatWrap">
      <div class="chatTitle" style="background: #f1f5fe">
        <!-- <van-icon @click="closeChat" class="close" name="cross" /> -->
        <img
          src="/new/arrowL.png"
          alt=""
          @click="closeChat"
          class="close"
          name="cross"
        />
        线上客服
      </div>
      <div class="chatContent" ref="chatContent">
        <div
          @contextmenu.prevent="onContextmenu(msg)"
          v-for="(msg, key) in messageList"
          :key="key"
          class="msgBox msgBoxRight"
          :class="{
            msgBoxLeft: msg.author == 'me',
          }"
          :style="{ background: returnLeft(msg.author == 'me') }"
        >
          <div class="contentReply" v-if="msg.replyContent">
            <div class="replyTitle">{{ msg.replyContent.from }}</div>

            <van-image
              v-if="fileMethod(msg.replyContent.msg)"
              width="10rem"
              height="10rem"
              fit="contain"
              :src="msg.replyContent.msg"
              @click="imgPrev(msg.replyContent.msg)"
            >
              <template v-slot:error>
                <p @click.stop="show = true">加载失败</p>
              </template>
            </van-image>
            <video-player
              v-if="videoMethd(msg.replyContent.msg)"
              class="video-player-box"
              ref="videoPlayer"
              :options="{
                // videojs options
                height: '100',
                width: '200',
                muted: true,
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                  {
                    src: msg.replyContent.msg,
                  },
                ],
              }"
              :playsinline="true"
              customEventName="customstatechangedeventname"
            >
            </video-player>
            <div
              v-html="msg.replyContent.msg.replace(/\n/g, '</br>')"
              v-if="
                !fileMethod(msg.replyContent.msg) &&
                !videoMethd(msg.replyContent.msg)
              "
            ></div>
          </div>
          <div
            v-html="msg.data.text.replace(/\n/g, '</br>')"
            v-if="msg.type == 'text'"
          ></div>
          <van-image
            v-if="msg.data.file && fileMethod(msg.data.file.url)"
            width="10rem"
            height="10rem"
            fit="contain"
            :src="msg.data.file.url"
            @click="imgPrev(msg.data.file.url)"
          >
            <template v-slot:error>
              <p @click.stop="show = true">加载失败</p>
            </template>
          </van-image>
          <video-player
            v-if="msg.data.file && videoMethd(msg.data.file.url)"
            class="video-player-box"
            ref="videoPlayer"
            :options="{
              // videojs options
              height: '100',
              width: '200',
              muted: true,
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [
                {
                  src: msg.data.file.url,
                },
              ],
            }"
            :playsinline="true"
            customEventName="customstatechangedeventname"
          >
          </video-player>
          <!-- videoMethd -->
          <p class="createTime">{{ returnTime(msg.created_at) }}</p>
        </div>
      </div>
      <div class="reply" v-if="reply">
        <van-icon name="replay" class="replyIcon" />
        <div
          style="width: 100%; overflow: hidden"
          v-html="reply.data.text"
          v-if="reply.type == 'text'"
        ></div>
        <van-image
          v-if="reply.data.file && fileMethod(reply.data.file.url)"
          width="50px"
          height="50px"
          fit="contain"
          :src="reply.data.file.url"
        >
          <template v-slot:error>
            <p @click.stop="show = true">加载失败</p>
          </template>
        </van-image>
        <video-player
          v-if="reply.data.file && videoMethd(reply.data.file.url)"
          class="video-player-box"
          ref="videoPlayer"
          :options="{
            height: '50',
            width: '50',
            muted: true,
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [
              {
                src: reply.data.file.url,
              },
            ],
          }"
          :playsinline="true"
          customEventName="customstatechangedeventname"
        >
        </video-player>
        <van-icon name="close" @click="reply = ''" class="close" />
      </div>
      <div class="chatSend">
        <textarea
          v-model="currentMessage"
          class="sendBox"
          placeholder="请输入想咨询的问题"
          name=""
          id=""
          cols="30"
          rows="10"
        >
        </textarea>
        <div class="rightSend">
          <label for="sendFile" class="btnHandler">
            <input
              type="file"
              accept="image/*,video/*"
              @change="changeFile"
              id="sendFile"
            />
            <i class="icon fas fa-paperclip"></i>
          </label>
          <div
            class="btnHandler"
            @click="sendMsg"
            :class="{ notMsg: !currentMessage.length }"
          >
            <i class="icon far fa-paper-plane"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import * as imageConversion from "image-conversion";
export default {
  name: "messageBox",
  data() {
    return {
      reply: "",
      show: false,
      file: "",
      isReLoadding: true,
      participants: [],
      // isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: "", // when set to a value matching the participant.id it shows the typing indicator for the specific user
      currentMessage: "",
      alwaysScrollToBottom: true,
      messageStyling: false,
      currentFiles: "",
      inFirst: true,
      inscroll: false,
    };
  },
  computed: {
    messageList() {
      return this.$store.state.message.messageList;
    },
    newMessagesCount() {
      return this.$store.state.message.newMessagesCount;
    },
    isChatOpen() {
      return this.$store.state.message.isChatOpen;
    },
    currentUser() {
      return this.$store.state.message.currentUser;
    },
  },
  watch: {
    isChatOpen() {
      if (this.isChatOpen) {
        setTimeout(() => {
          this.$refs.chatContent.scrollTop =
            this.$refs.chatContent.scrollHeight;
          // this.inFirst = false;
        }, 1000);
        this.$http.post(`/mobile/read`).then((res) => {
          if (res.data.code == 0) {
            this.$store.dispatch("message/setUnreadNum", 0);
          }
        });
      }
    },
    // messageList() {
    //   if (this.inFirst) {
    //     setTimeout(() => {
    //       this.$refs.chatContent.scrollTop =
    //         this.$refs.chatContent.scrollHeight;
    //       this.inFirst = false;
    //     }, 1500);
    //   }
    // },
  },
  methods: {
    returnLeft(boolean) {
      if (boolean) {
        return this.$returnColor().color1;
      }
    },
    onContextmenu(data) {
      this.$contextmenu({
        items: [
          {
            label: "回覆",
            onClick: () => {
              this.reply = data;
            },
          },
        ],
        event,
        customClass: "class-a",
        zIndex: 99999,
        minWidth: 230,
      });
      return false;
    },
    touchstart() {
      console.log(event);
    },
    returnTime(time) {
      return (
        time.split(" ")[0].split("-")[1] +
        "/" +
        time.split(" ")[0].split("-")[2] +
        " " +
        time.split(" ")[1]
      );
    },
    scrollPage() {
      if (event.target.scrollTop == 0) {
        this.handleScrollToTop();
      }
    },
    videoMethd(url) {
      if (
        url.split(".").pop() == "mov" ||
        url.split(".").pop() == "mp4" ||
        url.split(".").pop() == "avi"
      ) {
        return true;
      }
    },
    fileMethod(url) {
      if (
        url.split(".").pop() == "png" ||
        url.split(".").pop() == "jpg" ||
        url.split(".").pop() == "jepg"
      ) {
        return true;
      }
    },
    imgPrev(url) {
      ImagePreview({
        images: [url],
        closeable: true,
      });
    },
    async sendMsg() {
      if (!this.currentMessage.length) return;
      let sendData = {
        message: this.currentMessage,
        type: 0,
      };
      if (this.reply) {
        sendData.reply_id = this.reply.id;
      }
      await this.$http.post(`/mobile/chat`, sendData).then((res) => {
        if (res.data.code == 0) {
          this.reply = "";
          this.currentMessage = "";
        }
      });
      setTimeout(() => {
        this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
        // this.inFirst = false;
      }, 500);
    },
    async changeFile(e) {
      let sendData;
      sendData = new FormData();
      sendData.append("type", 1);
      if (
        e.target.files[0].type.indexOf("image") != -1 &&
        e.target.files[0].size > 1048576
      ) {
        await imageConversion
          .compressAccurately(e.target.files[0], 900)
          .then((res) => {
            sendData.append("message[]", res);
            return res;
          });
      } else {
        sendData.append("message[]", e.target.files[0]);
      }
      this.$http.post(`/mobile/chat`, sendData).then((res) => {
        if (res.data.code == 0) {
          this.reply = "";
        }
      });
    },
    editMessage() {
      console.log("s");
    },
    onRefresh() {
      this.$store.dispatch("setReload", true);
      this.$store.dispatch("setLoadding", true);
      setTimeout(() => {
        if (!this.$store.state.isInQuest) {
          this.$store.dispatch("setReload", false);
          this.$store.dispatch("setLoadding", false);
        }
      }, 2000);
    },
    sendMessage(text) {
      let sendData = {};
      sendData.type = text.type == "text" ? 0 : 1;
      if (sendData.type == 0) {
        sendData.message = text.data.text;
      } else {
        sendData.message = [text.data.file];
      }
      if (text) this.$http.post(`mobile/chat`, sendData);
    },
    onMessageWasSent(text) {
      let sendData = {};
      if (text.type == "text") {
        sendData.type = 0;
        sendData.message = text.data.text;
      } else {
        sendData = new FormData();
        sendData.append("message[]", text.data.file);
        sendData.append("type", 1);
        this.$http.post(`/mobile/chat`, sendData);
      }
      if (text.type == "text") this.$http.post(`/mobile/chat`, sendData);
    },
    openChat() {
      this.$store.dispatch("message/showMessageBox", true);
      this.$store.dispatch("message/setUnreadNum", 0);
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.$store.dispatch("message/showMessageBox", false);
    },
    async handleScrollToTop() {
      if (this.$store.state.message.messagePage.next_page) {
        if (this.inscroll) return;
        this.inscroll = true;
        setTimeout(() => {
          this.inscroll = false;
        }, 1000);
        await this.$store.dispatch(
          `message/getPrePageMessage`,
          this.$store.state.message.messagePage.current_page * 1 + 1
        );
      }
    },
    handleOnType(e) {
      console.log("Emit typing evesdsdnt", e);
    },
  },
  mounted() {
    document
      .querySelector(".chatContent")
      .addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    document
      .querySelector(".chatContent")
      .removeEventListener("scroll", this.scrollPage);
  },
};
</script>
<style lang="scss">
.sc-header {
  min-height: auto !important;
  h2 {
    line-height: 5px !important;
  }
}
// .sc-launcher,
// .sc-header {
//   background: #a9d297; /* Old browsers */
//   background: -moz-linear-gradient(
//     left,
//     #a9d297 0%,
//     #00c7aa 100%
//   ); /* FF3.6-15 */
//   background: -webkit-linear-gradient(
//     left,
//     #a9d297 0%,
//     #00c7aa 100%
//   ); /* Chrome10-25,Safari5.1-6 */
//   background: linear-gradient(
//     to right,
//     #a9d297 0%,
//     #00c7aa 100%
//   ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a9d297', endColorstr='#00c7aa',GradientType=1 ); /* IE6-9 */
// }
.chatWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
}
.chatTitle {
  position: relative;
  text-align: center;
  height: 45px;
  line-height: 45px;
  padding-left: 15px;
  font-size: 14px;
  color: black;
  .close {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
  }
}
.chatContent {
  height: calc(100% - 100px);
  overflow: scroll;
  background-color: #fff;
  // scroll-behavior: smooth;
  transition: 0.3s ease-in-out;
}
.chatSend {
  height: 55px;
  background-color: #f1f5fe;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  .icon {
    font-size: 20px;
    margin-right: 8px;
    margin-left: 8px;
  }
  #sendFile {
    width: 0;
    height: 0;
    opacity: 0;
    display: none;
  }
  .sendBox {
    border: none;
    background-color: white;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    font-size: 12px;
    height: 32px;
    padding-right: 80px;
    margin-left: 16px;
    line-height: 32px;
  }
  .rightSend {
    // position: absolute;
    right: 5px;
    display: flex;
    width: 70px;
    .btnHandler {
      // width: 50%;
      display: flex;
      justify-content: center;
    }
  }
}
.notMsg {
  opacity: 0.5 !important;
}
.chatContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  .msgBox {
    padding: 5px 10px;
    background-color: rgb(234, 234, 234);
    margin-top: 15px;
    display: inline;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    max-width: 90%;
    word-break: break-all;
    .contentReply {
      background-color: rgb(210, 210, 210);
      padding: 3px;
      border-radius: 5px;
    }
    .createTime {
      color: #646566;
      white-space: nowrap;
      font-size: 12px;
      width: 100%;
      text-align: right;
    }
  }
  .msgBoxRight {
    align-self: flex-end;
  }
  .msgBoxLeft {
    align-self: flex-start;
    // background-color: #83ce9d66;
    color: black;

    .contentReply {
      background-color: #83ce9d66;
    }
  }
  .bMsgBoxLeft {
    align-self: flex-start;
    background-color: #81c7e8;
    color: black;

    .contentReply {
      background-color: #81c7e8;
    }
  }
}
.video-player-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(black, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  .video-player-box {
    width: 100%;
  }
}
.video-js .vjs-big-play-button {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
.reply {
  background-color: #fff;
  border-top: 1px solid rgba(gray, 0.7);
  padding-top: 5px;
  padding: 5px 15px 0 15px;
  height: 60px;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 12px;
  width: 100%;
  white-space: nowrap;
  .replyIcon {
    font-size: 24px;
    color: rgb(80, 202, 167);
  }
  .close {
    font-size: 20px;
    margin-left: auto;
  }
}
.contentReply {
  background-color: #fff;
  // border-top: 1px solid rgba(gray, 0.7);
}
</style>
