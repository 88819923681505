import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import message from "./message";
import { Notify } from "vant";
import { Dialog } from "vant";
import VueCookie from "vue-cookie";
import Pusher from "pusher-js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inChat: false,
    prohibitScroll: false,
    showOverlay: false,
    selfData: {},
    orders: {
      data: [],
    },
    pOrders: {
      data: [],
    },
    isLoadding: false,
    loadingTimer: null,
    isReLoadding: false,
    isInQuest: false,
    dateLocal: {
      direction: "ltr",
      format: "yyyy-mm-dd-HH:MM",
      separator: " - ",
      applyLabel: "确认",
      weekLabel: "W",
      cancelLabel: "取消",
      customRangeLabel: "Custom Range",
      daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
      monthNames: [
        "01月",
        "02月",
        "03月",
        "04月",
        "05月",
        "06月",
        "07月",
        "08月",
        "09月",
        "10月",
        "11月",
        "12月",
      ],
      firstDay: 0,
      inNotify: false,
    },
    version: "",
    currentVersion: "2.0.0",
    except: {},
    isSubscribe: false,
    bindFADialog: false,
  },
  mutations: {
    setVersion(state, data) {
      state.version = data;
    },
    setSelf(state, data) {
      let self = JSON.parse(JSON.stringify(state.selfData));
      Object.keys(data[0]).forEach((e) => {
        self[e] = data[0][e];
      });
      state.selfData = self;
    },
    OVERLAY(state, boolean) {
      state.showOverlay = boolean;
    },
    SETSUBSCRIBE(state) {
      state.isSubscribe = true;
    },
    SETSELF(state, data) {
      state.selfData = data;
    },
    SETORDERS(state, orders) {
      state.orders = orders;
    },
    PUSHORDERS(state, orders) {
      let sendData = JSON.parse(JSON.stringify(state.orders));
      orders.data.forEach((data) => {
        let have = false;
        sendData.data.forEach((e) => {
          if (data.order_id == e.order_id) {
            have = true;
          }
        });
        if (!have) {
          sendData.data.push(data);
        }
        sendData.current_page = orders.current_page;
        sendData.next_page = orders.next_page;
      });
      state.orders = sendData;
    },
    PUSHPORDERS(state, orders) {
      let sendData = JSON.parse(JSON.stringify(state.pOrders));
      orders.data.forEach((data) => {
        let have = false;
        sendData.data.forEach((e) => {
          if (data.order_id == e.order_id) {
            have = true;
          }
        });
        if (!have) {
          sendData.data.push(data);
        }
        sendData.current_page = orders.current_page;
        sendData.next_page = orders.next_page;
      });
      state.pOrders = sendData;
    },
    SETPORDERS(state, orders) {
      state.pOrders = orders;
    },
    setExcept(state, except) {
      let data = JSON.parse(JSON.stringify(state.except));
      Object.keys(except).forEach((e) => {
        data[e] = except[e];
      });
      state.except = data;
    },
    SETLODDING(state, boolean) {
      state.isLoadding = boolean;
      // if (boolean && !state.loadingTimer) {
      //   state.loadingTimer = setTimeout(() => {
      //     state.isLoadding = false;
      //     clearTimeout(state.loadingTimer);
      //     state.loadingTimer = null;
      //     alert("似乎遇到些问题，即将刷新页面。");
      //     window.location.reload(true);
      //   }, 20000);
      // }
      // if (!boolean && state.loadingTimer) {
      //   clearTimeout(state.loadingTimer);
      //   state.loadingTimer = null;
      // }
    },
    SETRELOAD(state, boolean) {
      state.isReLoadding = boolean;
    },
    SETINQUEST(state, boolean) {
      state.isInQuest = boolean;
    },
    SETSCROLL(state, boolean) {
      state.prohibitScroll = boolean;
    },
    PUSHNEWORDER(state, order) {
      let orders = JSON.parse(JSON.stringify(state.orders));
      let have = false;
      orders.data.forEach((e) => {
        if (e.order_id == order.order_id) have = true;
      });
      if (have) return;
      orders.data.push(order);
      state.orders = orders;
    },
    PUSHNEWPORDER(state, order) {
      let orders = JSON.parse(JSON.stringify(state.pOrders));
      let have = false;
      orders.data.forEach((e) => {
        if (e.order_id == order.order_id) have = true;
      });
      if (have) return;
      if (typeof order.content == "string") {
        order.content = JSON.parse(order.content);
      }
      orders.data.unshift(order);
      state.pOrders = orders;
    },
    BINDFA(state, boolean) {
      state.bindFADialog = boolean;
    },
  },
  actions: {
    bindFA(context, boolean) {
      context.commit("BINDFA", boolean);
    },
    setProhibitScroll(context, boolean) {
      context.commit("SETSCROLL", boolean);
    },
    overlayHandler(context, boolean) {
      context.commit("OVERLAY", boolean);
    },
    getSelf({ commit, state }) {
      return axios.get(`/mobile/self`).then((res) => {
        if (res.data.code == 0) {
          state.inChat = true;
          commit("SETSELF", res.data.data);

          if (!state.isSubscribe) {
            axios.get(`/mobile/frontVersion`).then((version) => {
              if (version.data.code == 0) {
                state.version = version.data.data;
                commit("setVersion", version.data.data);
                if (state.currentVersion != version.data.data) {
                  Notify({
                    duration: 0,
                    message: "为了最新体验，请重新整理或重启应用。",
                    type: "danger",
                  });
                }
              }
            });
            commit("SETSUBSCRIBE", true);
            let domainTitleMap = {
              "running.etmart2.test": "/", // 本機測試
              "runn-f.test": "/", // 本機測試
              "running.etmart2.com": "/", // 測試機測試
              "running.etmart3.com": "/", // 測試機測試
              "pay.zhaofupay.com": "https://i.zhaofupay.com",
              "43.139.102.188": "http://43.139.102.188", // 聚合支付
              "43.138.153.196": "http://43.138.153.196", // 海淘
              "122.152.225.41": "http://122.152.225.41", // 天運
              "119.91.195.29": "http://119.91.195.29", // Li
              "42.194.148.159": "http://42.194.148.159",
            };
            let baseURL;
            baseURL =
              domainTitleMap[document.domain] || "https://888.ffb252.com";
            window.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
              cluster: "ap3",
              authEndpoint: baseURL + "/broadcasting/auth",
              encrypted: true,
              auth: {
                headers: {
                  Authorization: "Bearer " + VueCookie.get("token"),
                  "Access-Control-Allow-Origin": "*",
                },
              },
            });
            window.pusher.unsubscribe(
              `private-App.User.${res.data.data.member_id}`
            ); // 消息名称

            const channel = window.pusher.subscribe(
              `private-App.User.${res.data.data.member_id}`
            ); // 消息名称
            channel.bind(
              "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
              (note) => {
                if (note.type == "new_order") {
                  this.dispatch("noteNewOrder", note);
                  this.dispatch("getSelf");
                  document
                    .getElementById("orderAudio")
                    .play()
                    .catch(() => {
                      Dialog.alert({
                        message: "您有新订单",
                      });
                    });
                }
                if (note.type == "new_payment") {
                  document
                    .getElementById("paymentAudio")
                    .play()
                    .catch(() => {
                      Dialog.alert({
                        message: "您有新订单",
                      });
                    });
                  this.dispatch("getSelf");
                  this.dispatch("noteNewPOrder", note);
                }
                if (note.type == "chat") {
                  if (!this.state.message.isChatOpen) {
                    this.dispatch("message/showMessageBox", true);
                  }
                  if (note.from != "会员") {
                    document
                      .getElementById("audio")
                      .play()
                      .catch(() => {});
                  }
                  note.id = note.message_id;
                  this.dispatch("message/pushMsg", note);
                  this.dispatch(
                    "message/setUnreadNum",
                    this.state.message.newMessagesCount * 1 + 1
                  );
                }
              }
            );
          }
        }
      });
    },
    getOrder(context, params) {
      axios
        .get(`/mobile/orders`, {
          params,
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (params && params.page != 1) {
              context.commit("PUSHORDERS", res.data);
            } else {
              context.commit("SETORDERS", res.data);
            }
            // context.commit("setExcept", res.data.except);
          }
        });
    },
    getPOrder(context, params) {
      axios.get(`/mobile/payments`, { params }).then((res) => {
        if (res.data.code == 0) {
          if (params && params.page != 1) {
            context.commit("PUSHPORDERS", res.data);
          } else {
            context.commit("SETPORDERS", res.data);
          }
          // context.commit("setExcept", res.data.except);
          // let order = JSON.parse(JSON.stringify(res.data.data));
          // order = order.sort((a, b) => {
          //   return b.member_id - a.member_id;
          // });
          // context.commit("SETPORDERS", order);
          // context.commit("setExcept", res.data.except);
        }
      });
    },
    setLoadding(context, boolean) {
      context.commit("SETLODDING", boolean);
    },
    setReload(context, boolean) {
      context.commit("SETRELOAD", boolean);
    },
    setWaitQuest(context, boolean) {
      context.commit("SETINQUEST", boolean);
    },
    noteNewOrder(context, order) {
      context.commit("PUSHNEWORDER", order);
    },
    noteNewPOrder(context, order) {
      context.commit("PUSHNEWPORDER", order);
    },
  },
  modules: {
    message,
  },
});
