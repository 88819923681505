export default function decimalPoint(num) {
  // 小數點位數
  let range = 4;
  if (isNaN(Number(num))) {
    return Number(0).toFixed(range);
  }
  let returnNMum = Number(num)
    .toFixed(range + 1)
    .toString();
  return returnNMum.substring(0, returnNMum.length - 1);
}
