import Vue from "vue";
Vue.prototype.$returnColor = function returnColor() {
  if (
    document.domain == process.env.VUE_APP_TYH ||
    document.domain == "43.138.153.196" ||
    document.domain == "store.rzxl923.com" ||
    document.domain == "ht.hdsaj.com"
  ) {
    return {
      path: "blue",
      color1: "#81c7e8",
      color2: "#679dd4",
      color3: "#055d9b",
    };
  } else if (
    document.domain == process.env.VUE_APP_LEEJIA ||
    document.domain == "store.niks882.com"
  ) {
    return {
      path: "leejia",
      color1: "#D4B5D2",
      color2: "#7F4568",
      color3: "#423345",
    };
  } else if (
    document.domain == process.env.VUE_APP_PPP ||
    document.domain == "119.91.195.29" ||
    document.domain == process.env.VUE_APP_CROWN ||
    document.domain == process.env.VUE_APP_ZHAOFU ||
    document.domain == "store.kxzi342.com" ||
    document.domain == "store.rxjh382.com" ||
    document.domain == "43.139.102.188" ||
    document.domain == "store.rkzx921.com" ||
    document.domain == "store.rxlz861.com" ||
    document.domain == "b.hdsaj.com"
  ) {
    return {
      path: "greenblue",
      color1: "#a9d297",
      color2: "#00c7aa",
      color3: "#4065F0",
    };
  } else if (
    document.domain == "store.olzde8213.com" ||
    document.domain == "42.194.148.159"
  ) {
    return {
      path: "redblack",
      color1: "#ffb1b1",
      color2: "#00c7aa",
      color3: "#4065F0",
    };
  } else {
    return {
      path: "",
      color1: "#a9d297",
      color2: "#00c7aa",
      color3: "#4065F0",
    };
  }
};
