export default function thousands(num) {
  if (num) {
    var str = num.toString();
    var reg =
      str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
    return str.replace(reg, "$1,");
  } else {
    return num;
  }
}
