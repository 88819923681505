<template>
  <div class="home">
    <!-- top -->
    <van-dialog v-model="checkModal" :title="'出货检查'" class="checkModal">
      <div v-if="checkData" class="checkBody">
        <van-cell-group inset>
          <van-cell is-link @click="showMsg(checkData.on_duty_ds.note)">
            <template #title>
              <span class="custom-title">
                <van-icon
                  class="success"
                  v-if="checkData.on_duty_ds.check"
                  name="checked"
                />
                <van-icon class="err" v-else name="clear" />
                出货开关</span
              >
            </template>
            <template #right-icon>
              <p v-if="checkData.on_duty_ds.note" style="color: red">查看</p>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset>
          <van-cell is-link @click="showMsg(checkData.can_collection.note)">
            <template #title>
              <span class="custom-title">
                <van-icon
                  class="success"
                  v-if="checkData.can_collection.check"
                  name="checked"
                />
                <van-icon class="err" v-else name="clear" />
                出货权限</span
              >
            </template>
            <template #right-icon>
              <p v-if="checkData.can_collection.note" style="color: red">
                查看
              </p>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset>
          <van-cell is-link @click="showMsg(checkData.balance.note)">
            <template #title>
              <span class="custom-title">
                <van-icon
                  class="success"
                  v-if="checkData.balance.check"
                  name="checked"
                />
                <van-icon class="err" v-else name="clear" />
                余额</span
              >
            </template>
            <template #right-icon>
              <p v-if="checkData.balance.note" style="color: red">查看</p>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset>
          <van-cell
            is-link
            @click="showMsg(card.note)"
            v-for="(card, key) in checkData.cards"
            :key="key"
          >
            <template #title>
              <span class="custom-title">
                <van-icon class="success" v-if="card.check" name="checked" />
                <van-icon class="err" v-else name="clear" />
                {{ card.bank }}</span
              >
            </template>
            <template #right-icon>
              <p v-if="card.note" style="color: red">查看</p>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </van-dialog>
    <van-dialog
      v-model="transferModal"
      title="余额转让"
      show-cancel-button
      :before-close="confirmTrans"
    >
      <van-field
        v-model="trans.account"
        name="账号"
        label="账号"
        placeholder="账号"
        :rules="[{ required: true, message: '请填写账号' }]"
      />
      <van-field
        v-model="trans.amount"
        name="金额"
        label="金额"
        type="number"
        placeholder="金额"
        :rules="[{ required: true, message: '请填写金额' }]"
      />
      <van-field
        v-model="trans.withdraw_password"
        name="提现密码"
        label="提现密码"
        placeholder="提现密码"
        type="password"
        :rules="[{ required: true, message: '请填写提现密码' }]"
      />
    </van-dialog>
    <van-dialog
      v-model="exchangeModal"
      title="红利转余额"
      show-cancel-button
      :before-close="confirmExchange"
    >
      <p class="exchangeInfo" style="font-size: 12px">
        可转红利{{ selfData.bonus }}
      </p>
      <van-field
        v-model="amount"
        name="金额"
        label="金额"
        type="number"
        placeholder="金额"
        :rules="[{ required: true, message: '请填写金额' }]"
      />
    </van-dialog>
    <van-dialog
      v-model="show"
      :title="'自动' + $returnBuySel('进货')"
      show-cancel-button
      :before-close="changeDsBefore"
    >
      <p style="text-align: center; font-size: 14px; color: red">
        金额可设置区间 {{ selfData.df_min }} ~ {{ selfData.df_max }}
      </p>
      <van-field name="switch" label="自动">
        <template #input>
          <van-switch
            v-model="on_duty_df"
            active-color="#009aaa"
            inactive-color="#cccccc"
            size="20"
          />
        </template>
      </van-field>
      <van-field
        v-if="selfData.alter_self_df_min == 1"
        v-model="selfData.self_df_min"
        name="最低"
        label="最低"
        placeholder="最低"
        type="number"
        :rules="[{ required: true, message: '请填写最低' }]"
      />
      <van-field
        v-else
        disabled
        v-model="selfData.df_min"
        name="最低"
        label="最低"
        placeholder="最低"
        type="number"
        :rules="[{ required: true, message: '请填写最低' }]"
      />
      <van-field
        v-model="selfData.self_df_max"
        name="最高"
        label="最高"
        type="number"
        placeholder="最高"
        :rules="[{ required: true, message: '请填写最高' }]"
      />
    </van-dialog>
    <!-- top -->
    <div class="wrap">
      <!-- left-icon="volume-o" -->
      <van-notice-bar :scrollable="false" class="notifyBar">
        <!-- :style="retrunBgColor()" -->
        <div slot="left-icon" class="leftIcon">
          <img src="@/assets/images/home page_notice_icon1.png" alt="" />
        </div>
        <!-- <div
          slot="right-icon"
          class="rightIcon"
          @click="$router.push('/bulletin')"
        >
          <img src="@/assets/images/home page_notice_icon2.png" alt="" />
        </div> -->
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
          style="color: black"
        >
          <van-swipe-item
            @click="showContent(ann)"
            v-for="ann in annList"
            :key="ann.id"
            >{{ ann.created_at }} {{ ann.title }}</van-swipe-item
          >
        </van-swipe>
      </van-notice-bar>
      <div class="inviteWrap">
        <p
          style="
            font-size: 30px;
            font-family: Reeji-CloudZongYi-GB;
            font-weight: 400;
          "
        >
          邀好友 得现金
        </p>
        <p
          style="
            font-size: 17px;
            font-family: Reeji-CloudZongYi-GB;
            font-weight: 400;
          "
        >
          邀得越多，奖励越多
        </p>
        <van-button
          @click="$router.push('/invites')"
          round
          style="
            height: 30px;
            margin-top: 20px;
            font-size: 12px;
            font-weight: bold;
          "
          color="#44BBF4"
          >立即邀请</van-button
        >
        <img src="/new/s1.png" alt="" class="s1" />
        <img src="/new/s2.png" alt="" class="s2" />
        <img src="/new/s3.png" alt="" class="s3" />
        <img src="/new/g.png" alt="" class="g" />
      </div>

      <div class="cardWrap">
        <div class="cardContent">
          <div
            v-if="selfData.can_payment"
            @click="$router.push({ path: 'ordersP' })"
            class="cardBox"
          >
            <p class="badge" v-if="selfData.payment_count">
              {{ selfData.payment_count }}
            </p>
            <div>
              <img :src="$returnColor().path + '/new/icon1.png'" alt="" />
              <p class="name">{{ $returnBuySel("进货") }}</p>
            </div>
          </div>
          <div @click="$router.push({ path: 'ordersC' })" class="cardBox">
            <p class="badge" v-if="selfData.order_count">
              {{ selfData.order_count }}
            </p>
            <div>
              <img :src="$returnColor().path + '/new/icon2.png'" alt="" />
              <p class="name">{{ $returnBuySel("出货") }}</p>
            </div>
          </div>
          <div @click="$router.push({ path: 'bulletin' })" class="cardBox">
            <div>
              <img :src="$returnColor().path + '/new/bulletin.png'" alt="" />
              <p class="name">公告</p>
            </div>
          </div>
          <div
            @click="$router.push('/deposit')"
            class="cardBox"
            v-if="selfData.can_deposit == 1 || selfData.can_deposit_usdt == 1"
          >
            <div>
              <img :src="$returnColor().path + '/new/icon3.png'" alt="" />
              <p class="name">充值</p>
            </div>
          </div>
          <div class="cardBox" @click="$router.push('/withdraw')">
            <div>
              <img :src="$returnColor().path + '/new/icon4.png'" alt="" />
              <p class="name">提款</p>
            </div>
          </div>
          <i class="stateI"></i><i class="stateI"></i><i class="stateI"></i
          ><i class="stateI"></i><i class="stateI"></i>
        </div>
      </div>
      <div class="inviteWrap2" style="margin-top: 40px">
        <p
          style="
            font-size: 20px;
            color: #4361ff;
            font-family: Reeji-CloudZongYi-GB;
            font-weight: 400;
            margin-top: 10px;
          "
        >
          严选精品
        </p>
        <van-button
          round
          @click="goShopping"
          style="
            height: 30px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: bold;
          "
          color="#4361ff"
          >前往卖场</van-button
        >
        <div class="bg">
          <img src="/new/homeg1/b1.png" alt="" />
          <img src="/new/homeg1/b2.png" alt="" />
        </div>
        <img src="/new/homeg1/s1.png" alt="" class="s1" />
        <img src="/new/homeg1/s2.png" alt="" class="s2" />
        <img src="/new/homeg1/s3.png" alt="" class="s3" />
        <img src="/new/homeg1/g.png" alt="" class="g" />
      </div>
      <div class="inviteWrap3" style="margin-top: 15px">
        <p
          style="
            font-size: 20px;
            color: #3dbef6;
            font-family: Reeji-CloudZongYi-GB;
            font-weight: 400;
            margin-top: 10px;
          "
        >
          平台介绍
        </p>
        <van-button
          round
          style="
            height: 30px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: bold;
          "
          color="#3DBEF6"
          >了解更多</van-button
        >
        <div class="bg">
          <img src="/new/homeg2/b1.png" alt="" />
          <img src="/new/homeg2/b2.png" alt="" />
        </div>
        <img src="/new/homeg2/s1.png" alt="" class="s1" />
        <img src="/new/homeg2/s2.png" alt="" class="s2" />
        <img src="/new/homeg2/s3.png" alt="" class="s3" />
        <img src="/new/homeg2/g.png" alt="" class="g" />
      </div>
    </div>
    <foot />
  </div>
</template>

<script>
import { Toast } from "vant";
import foot from "../components/footer";
import { mapState } from "vuex";
export default {
  components: { foot },
  data() {
    return {
      exchangeModal: false,
      show: false,
      on_duty_ds: false,
      amount: "",
      annList: [],
      on_duty_df: false,
      transferModal: false,
      checkModal: false,
      checkData: false,
      trans: {},
    };
  },
  computed: {
    ...mapState(["orders", "pOrders", "selfData"]),
  },
  methods: {
    goShopping() {
      location.href = "https://shopping.rxlz861.com/";
    },
    retrunBgColor() {
      if (document.domain == "store.olzde8213.com") {
        return "background:black; color:white";
      }
    },
    retrunTitleColor() {
      if (document.domain == "store.olzde8213.com") {
        return "color:black";
      }
    },
    showMsg(msg) {
      if (!msg) return;
      this.$dialog.alert({
        message: msg,
      });
    },
    showCheck() {
      this.$http.get(`/mobile/selfCheck`).then((res) => {
        if (res.data.code == 0) {
          this.checkData = res.data.data;
          this.checkModal = true;
        }
      });
    },
    confirmTrans(method, done) {
      let vm = this;
      if (method == "confirm") {
        if (!this.trans.account) {
          this.$toast.fail("请输入账号");
        }
        if (!this.trans.amount) {
          this.$toast.fail("请输入金额");
        }
        if (!this.trans.withdraw_password) {
          this.$toast.fail("请输入提现密码");
        }
        this.$dialog.confirm({
          title: "请确认转账信息是否正确",
          message: `
              <p style="width:100%;text-align:left;margin:0;padding:0;padding-top:15px">转入账号: ${vm.trans.account}</p>
              <p style="width:100%;text-align:left;margin:0;padding:0">金额: ${vm.trans.amount}</p>`,
          beforeClose(method, done2) {
            if (method == "confirm") {
              vm.$http.post(`/mobile/transfer`, vm.trans).then((res) => {
                if (res.data.code == 0) {
                  vm.$store.dispatch("getSelf");
                  vm.$toast.success("成功");
                  done();
                } else {
                  done(false);
                }
                done2();
              });
            } else {
              done(false);
            }
            done2();
          },
        });
      } else {
        done();
      }
    },
    showContent(list) {
      this.$dialog.confirm({
        title: list.title,
        message: list.content,
        showCancelButton: false,
      });
    },
    changeDsBefore(method, done) {
      if (method == "confirm") {
        if (this.selfData.alter_self_df_min == 1) {
          if (this.selfData.self_df_min * 1 < this.selfData.df_min * 1) {
            this.$dialog.confirm({
              title: "提醒",
              message: "请输入有效范围",
              showCancelButton: false,
            });
            done(false);
            return;
          }
          if (this.selfData.self_df_max * 1 < this.selfData.self_df_min * 1) {
            this.$dialog.confirm({
              title: "提醒",
              message: "请输入有效范围",
              showCancelButton: false,
            });
            done(false);
            return;
          }
        }
        if (this.selfData.self_df_max * 1 > this.selfData.df_max * 1) {
          this.$dialog.confirm({
            title: "提醒",
            message: "请输入有效范围",
            showCancelButton: false,
          });
          done(false);
          return;
        }
        if (this.selfData.self_df_max * 1 > this.selfData.df_max * 1) {
          this.$dialog.confirm({
            title: "提醒",
            message: "请输入有效范围",
            showCancelButton: false,
          });
          done(false);
          return;
        }
        let sendData = {
          on_duty_df: this.on_duty_df ? 1 : 0,
          self_df_min: this.selfData.self_df_min,
          self_df_max: this.selfData.self_df_max,
        };
        if (!this.selfData.alter_self_df_min == 1) {
          delete sendData.self_df_min;
        }
        this.$http.post(`/mobile/personalSetting`, sendData).then((res) => {
          if (res.data.code == 0) {
            this.$store.commit("setSelf", res.data.data);
            // this.$store.dispatch("getSelf");
            done();
          } else {
            done(false);
          }
        });
      } else {
        done();
      }
    },
    confirmExchange(method, done) {
      if (method == "confirm") {
        this.$http
          .post(`/mobile/exchange`, {
            amount: this.amount,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$store.dispatch("getSelf");
              done();
            } else {
              done(false);
            }
          });
      } else {
        done();
      }
    },
    changeDs(checked) {
      this.$dialog
        .confirm({
          title: "提醒",
          message: "是否切自动" + this.$returnBuySel("出货") + "?",
        })
        .then(() => {
          this.$http
            .post(`/mobile/personalSetting`, {
              on_duty_ds: checked,
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$store.commit("setSelf", res.data.data);
                // this.$store.dispatch("getSelf");
              }
            });
        });
    },
    changeDf(checked) {
      this.on_duty_df = !checked;
      this.show = true;
    },
    getOrder() {
      this.$store.dispatch("getOrder", { page: 1, status: 0 });
    },
    getPOrder() {
      this.$store.dispatch("getPOrder", { page: 1, status: 0 });
    },
    text(test) {
      Toast(test);
    },
  },
  created() {
    // this.getPOrder();
    // if (!this.$store.state.isSubscribe) {
    this.$store.dispatch("getSelf");

    this.$http.get(`/mobile/anns`).then((res) => {
      if (res.data.code == 0) {
        this.annList = res.data.data;
      }
    });
    // }
  },
};
</script>
<style lang="scss" scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
  overflow: hidden;
}

.swipeWrap {
  display: flex;
  justify-content: center;
  // margin-top: 15px;
  margin-bottom: 15px;
  position: relative;

  .bsg {
    position: absolute;
    width: 377px;
    height: 227px;
  }

  .my-swipe {
    width: 90%;
    border-radius: 10px;
  }
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

.selectBtn {
  height: 55px;

  p {
    margin: 0 !important;
  }
}

.demo-home-nav__block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin: 0 0 12px;
  padding-left: 20px;
  color: #323233;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  background: #f7f8fa;
  border-radius: 10px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  width: 175px;
}

.demo-home-nav__icon {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 16px;
  height: 16px;
  margin-top: -8px;
}

.midContent {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;

  .left {
    flex: 1;
  }

  .right {
    flex: 1.5;

    .van-cell-group--inset {
      margin-bottom: 10px;
    }

    .van-cell {
      padding: 8px 12px !important;
    }
  }
}

.cardWrap {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #fff;
  // border-radius: 10px;
  // padding: 15px;
  // margin-top: 15px;

  .cardTitle {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(gray, 0.3);
    flex-wrap: wrap;
    font-size: 14px;

    .tfInfo {
      margin: 0;
      width: 100%;
      font-size: 12px;
    }

    .right {
      display: flex;

      span {
        display: flex;
        width: 100%;
        align-items: center;
      }
    }
  }

  .cardContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 295px;

    .stateI {
      width: 80px;
      height: 0;
    }

    .cardBox {
      .badge {
        position: absolute;
        right: -5px;
        top: -5px;
        border-radius: 100px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        background-color: red;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      position: relative;
      width: 80px;
      height: 80px;
      padding: 5px;
      // margin: 15px 5px;
      box-shadow: 0px 0px 5px rgba(#000000, 0.05);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      background-color: #fff;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 100%;

        img {
          width: 25px;
          margin-top: auto;
          margin-bottom: 10px;
        }
      }

      .img {
        font-size: 30px;
      }

      p {
        margin: 0;
      }

      .name {
        font-size: 14px;
        font-family: Noto Sans SC;
        font-weight: 500;
        // margin-top: 20px;
      }

      .info {
        color: gray;
        font-size: 14px;
      }
    }
  }
}

.ttxx {
  .cardWrap {
    min-height: auto;
  }

  .cardContent {
    width: 330px !important;

    .cardBox {
      width: 50px;
      height: 50px;

      div {
        justify-content: center !important;
      }

      // margin-top: 0px;
      img {
        margin-bottom: auto !important;
      }
    }

    .name {
      position: absolute;
      bottom: -25px;
      font-weight: initial;
    }

    .stateI {
      width: 0px;
      display: none;
    }
  }
}

.infoPay {
  margin: 0 10px;

  .van-cell__title {
    text-align: left;
  }

  margin-top: 15px;
}

.exchangeInfo {
  color: red;
  text-align: center;
}

.notifyBar {
  border-radius: 20px;
  background-color: #fff;
  height: 30px;
  width: 56%;
  margin: 0 auto;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testLeft,
.testRight {
  display: flex;
  align-items: center;
  z-index: 999;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
}

.testLeft {
  left: 10px;
}

.testRight {
  right: 10px;
}

.leftIcon,
.rightIcon {
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftIcon {
  img {
    width: 15px;
    height: 15px;
  }
}

.rightIcon {
  img {
    width: 13px;
    height: 10px;
  }
}

.t1,
.t2 {
  background-color: #fff;
  height: 160px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
}

.t1 {
  width: 285px;
  display: flex;

  .title {
    font-size: 12px;
    color: #9d9b9b;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .numF {
      color: #333333;
      font-size: 12pt;
      line-height: 30px;
      margin-top: 5px;
    }
  }

  .left {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .right {
    display: flex;
    justify-content: center;
    width: 50%;
  }

  .content {
    width: 100%;
    display: flex;
  }
}

.t2 {
  width: 300px;
  display: flex;

  .set {
    display: flex;
    align-items: center;
    height: 35px;

    img {
      width: 15px;
      margin-left: 5px;
    }
  }

  .content {
    width: 100%;
    display: flex;
  }

  .title {
    font-size: 10px;
    color: #9d9b9b;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .numF {
      color: #333333;
      font-size: 14px;
      line-height: 35px;
      font-weight: 500;
    }
  }

  .left {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .right {
    display: flex;
    width: 50%;
    justify-content: flex-start;
  }
}

.loopWrap {
  display: flex;
  justify-content: center;

  .content {
    margin-top: 15px;
  }
}

.home {
  // background-image: attr(image url);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f1f5fe;
  // background-attachment: fixed;
  min-height: 100vh;
}

.balanceWrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;

  .b1 {
    font-size: 10px;
    font-weight: bold;
  }

  .b2 {
    font-size: 24pt;
  }
}

.changeTitle {
  font-size: 11pt;
  font-weight: bold;

  .active {
    color: #9d9b9b;
  }

  span {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.numF {
  font-family: "Montserrat" !important;
}

.selfCheck {
  cursor: pointer;
  // color: rgb(99, 205, 161);
  font-size: 12px !important;
  line-height: 35px;
}

i.err {
  color: red;
}

i.success {
  color: green;
}

.inviteWrap {
  height: 170px;
  background-color: #4361ff;
  width: 90%;
  border-radius: 19px;
  margin: 20px auto;
  color: white;
  padding: 20px;
  position: relative;
  max-width: 335px;
  margin-bottom: 8px;

  .s1 {
    width: 24px;
    position: absolute;
    top: 6px;
    right: 98px;
  }
  .s2 {
    width: 16px;
    position: absolute;
    bottom: 57px;
    right: 139px;
  }
  .s3 {
    width: 8px;
    position: absolute;
    bottom: 23px;
    right: 203px;
  }
  .g {
    position: absolute;
    width: 141px;
    top: 16px;
    right: 0;
    bottom: 0;
  }
}
.inviteWrap2 {
  height: 150px;
  background-color: white;
  width: 90%;
  border-radius: 19px;
  margin: 20px auto;
  color: white;
  padding: 20px;
  position: relative;
  max-width: 335px;
  margin-bottom: 8px;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    img {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      height: 100%;
      width: 60%;
      &:last-child {
        width: 50%;
      }
    }
  }

  .s1 {
    width: 24px;
    position: absolute;
    top: 10px;
    right: 170px;
  }
  .s2 {
    width: 16px;
    position: absolute;
    bottom: 43px;
    right: 136px;
  }
  .s3 {
    width: 8px;
    position: absolute;
    bottom: 80px;
    right: 203px;
  }
  .g {
    position: absolute;
    width: 120px;
    top: 11px;
    right: 0;
    bottom: 0;
  }
}

.inviteWrap3 {
  height: 150px;
  background-color: white;
  width: 90%;
  border-radius: 19px;
  margin: 20px auto;
  color: white;
  padding: 20px;
  position: relative;
  max-width: 335px;
  margin-bottom: 8px;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    img {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      height: 100%;
      width: 60%;
      &:last-child {
        width: 50%;
      }
    }
  }

  .s1 {
    width: 24px;
    position: absolute;
    top: 10px;
    right: 170px;
  }
  .s2 {
    width: 16px;
    position: absolute;
    bottom: 43px;
    right: 136px;
  }
  .s3 {
    width: 8px;
    position: absolute;
    bottom: 80px;
    right: 203px;
  }
  .g {
    position: absolute;
    width: 120px;
    top: 11px;
    right: 0;
    bottom: 0;
  }
}
</style>
