<template>
  <!-- footer -->
  <van-tabbar route active-color="#4065f0" class="newFoot">
    <van-tabbar-item replace to="/" icon="home-o">
      主页
      <template #icon="props">
        <img :src="props.active ? '/new/homeA.png' : '/new/home.png'" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item
      replace
      to="/ordersP"
      icon="gold-coin-o"
      v-if="selfData.can_payment"
    >
      进货
      <template #icon="props">
        <img :src="props.active ? '/new/piA.png' : '/new/pi.png'" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/ordersC" icon="cash-back-record">
      出货
      <template #icon="props">
        <img :src="props.active ? '/new/oiA.png' : '/new/oi.png'" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item
      :badge="$store.state.message.newMessagesCount || ''"
      replace
      icon="comment-circle-o"
      @click="$store.dispatch('message/showMessageBox', true)"
    >
      客服
      <template #icon="props">
        <img :src="props.active ? '/new/si.png' : '/new/si.png'" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/selfData" icon="friends-o">
      个人
      <template #icon="props">
        <img :src="props.active ? '/new/miA.png' : '/new/mi.png'" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
  <!-- footer -->
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["selfData"]),
  },
  methods: {
    returnFColor() {
      if (document.domain == "store.olzde8213.com") {
        return "#A12412";
      }
      return "#009aaa";
    },
    returnDomain() {
      if (
        document.domain == "runn-f.test" ||
        document.domain == "store.rkzx921.com" ||
        document.domain == "119.91.195.29"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.$http.get(`/mobile/chat`).then((res) => {
      let num = 0;
      if (res.data.code == 0) {
        res.data.data.forEach((e) => {
          if (e.read_at) return;
          num++;
        });
      }
      if (num) {
        this.$store.dispatch("message/setUnreadNum", num);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.ttxx .newFoot {
  bottom: 30px;
  right: 10px;
  margin: 0 10px;
  left: 10px;
  width: 90%;
  border-radius: 50px;
  overflow: hidden;
  height: 65px;
  box-shadow: 0px 20px 40px 0px #00000029;
}
.van-tabbar-item__icon img {
  height: 22px;
  margin-bottom: 2px;
}
</style>

<style lang="scss">
.ttxx {
  .van-tabbar-item__text {
    // display: none;
  }
}
</style>
