var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isChatOpen),expression:"isChatOpen"}]},[_c('div',{staticClass:"chatWrap"},[_c('div',{staticClass:"chatTitle",staticStyle:{"background":"#f1f5fe"}},[_c('img',{staticClass:"close",attrs:{"src":"/new/arrowL.png","alt":"","name":"cross"},on:{"click":_vm.closeChat}}),_vm._v(" 线上客服 ")]),_c('div',{ref:"chatContent",staticClass:"chatContent"},_vm._l((_vm.messageList),function(msg,key){return _c('div',{key:key,staticClass:"msgBox msgBoxRight",class:{
          msgBoxLeft: msg.author == 'me',
        },style:({ background: _vm.returnLeft(msg.author == 'me') }),on:{"contextmenu":function($event){$event.preventDefault();return _vm.onContextmenu(msg)}}},[(msg.replyContent)?_c('div',{staticClass:"contentReply"},[_c('div',{staticClass:"replyTitle"},[_vm._v(_vm._s(msg.replyContent.from))]),(_vm.fileMethod(msg.replyContent.msg))?_c('van-image',{attrs:{"width":"10rem","height":"10rem","fit":"contain","src":msg.replyContent.msg},on:{"click":function($event){return _vm.imgPrev(msg.replyContent.msg)}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('p',{on:{"click":function($event){$event.stopPropagation();_vm.show = true}}},[_vm._v("加载失败")])]},proxy:true}],null,true)}):_vm._e(),(_vm.videoMethd(msg.replyContent.msg))?_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"video-player-box",attrs:{"options":{
              // videojs options
              height: '100',
              width: '200',
              muted: true,
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [
                {
                  src: msg.replyContent.msg,
                },
              ],
            },"playsinline":true,"customEventName":"customstatechangedeventname"}}):_vm._e(),(
              !_vm.fileMethod(msg.replyContent.msg) &&
              !_vm.videoMethd(msg.replyContent.msg)
            )?_c('div',{domProps:{"innerHTML":_vm._s(msg.replyContent.msg.replace(/\n/g, '</br>'))}}):_vm._e()],1):_vm._e(),(msg.type == 'text')?_c('div',{domProps:{"innerHTML":_vm._s(msg.data.text.replace(/\n/g, '</br>'))}}):_vm._e(),(msg.data.file && _vm.fileMethod(msg.data.file.url))?_c('van-image',{attrs:{"width":"10rem","height":"10rem","fit":"contain","src":msg.data.file.url},on:{"click":function($event){return _vm.imgPrev(msg.data.file.url)}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('p',{on:{"click":function($event){$event.stopPropagation();_vm.show = true}}},[_vm._v("加载失败")])]},proxy:true}],null,true)}):_vm._e(),(msg.data.file && _vm.videoMethd(msg.data.file.url))?_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"video-player-box",attrs:{"options":{
            // videojs options
            height: '100',
            width: '200',
            muted: true,
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [
              {
                src: msg.data.file.url,
              },
            ],
          },"playsinline":true,"customEventName":"customstatechangedeventname"}}):_vm._e(),_c('p',{staticClass:"createTime"},[_vm._v(_vm._s(_vm.returnTime(msg.created_at)))])],1)}),0),(_vm.reply)?_c('div',{staticClass:"reply"},[_c('van-icon',{staticClass:"replyIcon",attrs:{"name":"replay"}}),(_vm.reply.type == 'text')?_c('div',{staticStyle:{"width":"100%","overflow":"hidden"},domProps:{"innerHTML":_vm._s(_vm.reply.data.text)}}):_vm._e(),(_vm.reply.data.file && _vm.fileMethod(_vm.reply.data.file.url))?_c('van-image',{attrs:{"width":"50px","height":"50px","fit":"contain","src":_vm.reply.data.file.url},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('p',{on:{"click":function($event){$event.stopPropagation();_vm.show = true}}},[_vm._v("加载失败")])]},proxy:true}],null,false,2261117871)}):_vm._e(),(_vm.reply.data.file && _vm.videoMethd(_vm.reply.data.file.url))?_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box",attrs:{"options":{
          height: '50',
          width: '50',
          muted: true,
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [
            {
              src: _vm.reply.data.file.url,
            },
          ],
        },"playsinline":true,"customEventName":"customstatechangedeventname"}}):_vm._e(),_c('van-icon',{staticClass:"close",attrs:{"name":"close"},on:{"click":function($event){_vm.reply = ''}}})],1):_vm._e(),_c('div',{staticClass:"chatSend"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentMessage),expression:"currentMessage"}],staticClass:"sendBox",attrs:{"placeholder":"请输入想咨询的问题","name":"","id":"","cols":"30","rows":"10"},domProps:{"value":(_vm.currentMessage)},on:{"input":function($event){if($event.target.composing)return;_vm.currentMessage=$event.target.value}}}),_c('div',{staticClass:"rightSend"},[_c('label',{staticClass:"btnHandler",attrs:{"for":"sendFile"}},[_c('input',{attrs:{"type":"file","accept":"image/*,video/*","id":"sendFile"},on:{"change":_vm.changeFile}}),_c('i',{staticClass:"icon fas fa-paperclip"})]),_c('div',{staticClass:"btnHandler",class:{ notMsg: !_vm.currentMessage.length },on:{"click":_vm.sendMsg}},[_c('i',{staticClass:"icon far fa-paper-plane"})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }