<template>
  <!-- <div style="min-height: 100vh"> -->
  <van-pull-refresh
    :head-height="150"
    v-model="isReLoadding"
    @refresh="onRefresh"
    style="min-height: 100vh; background-color: #f1f5fe"
    :disabled="$store.state.message.isChatOpen"
    id="app"
    class="ttxx"
    :class="{
      hideScroll:
        $store.state.prohibitScroll || $store.state.message.isChatOpen,
    }"
  >
    <van-dialog
      class="FAModal"
      :before-close="bindBefore"
      v-model="$store.state.bindFADialog"
      title="绑定谷歌验证码"
    >
      <div style="width: 100%; display: flex; justify-content: center">
        <vue-qrcode
          :value="
            encodeURI(`otpauth://totp/${selfData.username}?secret=${secret}`)
          "
        />
      </div>
      <van-form @submit="bindFA" ref="oneCodeForm">
        <van-field label="密钥" :value="secret" readonly />
        <van-button
          v-clipboard:copy="secret"
          v-clipboard:success="onCopy"
          type="primary"
          block
          >复制</van-button
        >
        <van-field
          v-model="oneCode"
          name="验证码"
          label="验证码"
          placeholder="验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        />
      </van-form>
    </van-dialog>
    <van-notify v-model="showLock" type="danger" class="lockWrap">
      <van-icon name="bell" style="margin-right: 4px" />
      <span>您的账户已被封禁，如有疑问请洽客服</span>
      <van-icon name="cross" class="closeIcon" @click="lock = false" />
    </van-notify>
    <audio
      :playsinline="true"
      :muted="true"
      controls
      class="audio"
      id="paymentAudio"
      src="/music/payment.mp3"
    ></audio>
    <audio
      :playsinline="true"
      :muted="true"
      controls
      class="audio"
      id="orderAudio"
      src="/music/order.mp3"
    ></audio>
    <audio
      :playsinline="true"
      :muted="true"
      controls
      class="audio"
      id="audio"
      src="/music/newOrder.mp3"
    ></audio>
    <audio
      class="audio"
      :muted="true"
      controls
      id="test"
      src="/music/test.m4a"
    ></audio>
    <van-overlay :show="isLoadding" class-name="overlayClass">
      <div class="loadWrapper" @click.stop>
        <i class="fas fa-spinner fa-pulse icon"></i>
        <div class="block" />
      </div>
    </van-overlay>
    <div style="min-height: 100vh">
      <!-- content -->
      <router-view />
      <!-- content -->
    </div>

    <messageBox
      v-if="$store.state.selfData.member_id && $route.name != 'userLogin'"
    />
  </van-pull-refresh>
  <!-- </div> -->
</template>

<script>
import messageBox from "./components/messageBox/index.vue";
import { Dialog } from "vant";
import VueQrcode from "vue-qrcode";

export default {
  components: {
    messageBox,
    VueQrcode,
  },
  data() {
    return {
      active: "home",
      lock: true,
      secret: "",
      oneCode: "",
    };
  },
  watch: {
    "$store.state.bindFADialog": function (newValue) {
      if (newValue) {
        this.set2FA();
      }
    },
  },
  computed: {
    showLock() {
      if (this.lock && this.$store.state.selfData.locked == 1) {
        return true;
      } else {
        return false;
      }
    },
    selfData() {
      return this.$store.state.selfData;
    },
    showOverlay() {
      return this.$store.state.showOverlay;
    },
    isReLoadding: {
      get() {
        return this.$store.state.isReLoadding;
      },
      set() {
        return this.$store.state.isReLoadding;
      },
    },
    isLoadding() {
      return this.$store.state.isLoadding;
    },
  },
  methods: {
    onCopy() {
      this.$toast("复制成功");
    },
    bindBefore(method, done) {
      if (method == "confirm") {
        this.$refs.oneCodeForm.submit();
        done(false);
      } else {
        this.$store.dispatch("bindFA", false);
        done();
        return;
      }
    },
    set2FA() {
      this.oneCode = "";
      this.$http.get(`/mobile/2fa`).then((res) => {
        if (res.data.code == 0) {
          this.secret = res.data.data.secret;
        } else {
          this.$store.dispatch("bindFA", false);
        }
      });
    },
    bindFA() {
      this.$http
        .post(`mobile/2fa`, {
          oneCode: this.oneCode,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$toast("成功");
            setTimeout(() => {
              this.$store.dispatch("getSelf");
            }, 1000);
            this.$store.dispatch("bindFA", false);
          }
        });
    },
    onRefresh() {
      if (this.$store.state.message.isChatOpen) {
        this.$store.dispatch("setReload", false);
        return;
      }
      location.reload();
    },
    setAutio() {
      document.getElementById("orderAudio").muted = false;
      document.getElementById("paymentAudio").muted = false;
      document.getElementById("audio").muted = false;
      window.removeEventListener("click", this.setAutio);
    },
  },
  mounted() {
    window.addEventListener("click", this.setAutio);
    // window.addEventListener("scroll", this.setAutio);
  },
  created() {
    window.scrollTo(0, 0);
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      if (document.querySelector("#audio").muted) {
        Dialog.alert({
          message: "请点选确认开启通知音讯",
        });
      }
    } else {
      setTimeout(() => {
        document
          .getElementById("test")
          .play()
          .catch(() => {
            Dialog.alert({
              message: "请点选确认开启通知音讯",
            });
          });
      }, 5000);
    }
    setTimeout(() => {
      if (this.$route.name != "Home" && this.$route.name != "register") {
        this.$store.dispatch("getSelf");
      }
    }, 100);
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Reeji-CloudZongYi-GB"; /* 字體名稱 */
  src: url("/new/Reeji-CloudZongYi-GB-Regular-206788.ttf") format("truetype");
  font-weight: normal; /* 字體粗細 */
  font-style: normal; /* 字體樣式 */
}

* {
  box-sizing: border-box !important;
  font-family: "Noto Sans SC";
  // Reeji-CloudZongYi-GB
}

#app {
  font-family: Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: white;
  // padding-bottom: 50px;
  font-size: 14px;
  width: 100vw;
  overflow-x: hidden;

  // font-weight: 300;
  i {
    font-weight: normal;
  }
}

.loadWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    color: white !important;
    font-size: 40px !important;
  }

  .block {
  }
}

p {
  margin: 0;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.van-overlay {
  // z-index: 9999 !important;
}

.wrap {
  background-attachment: fixed;
  padding: 20px 0px;
  padding-bottom: 100px; // padding-top: 40px;
  // transform: scale(1.05);
  > div {
  }
}

.captchaInput {
  position: relative;

  .van-icon__image {
    position: absolute;
    right: 0;
    top: -16px;
    height: 2em !important;
    width: auto !important;
  }
}

.sc-chat-window,
.sc-launcher {
  z-index: 9999;
}

.sc-launcher,
.sc-open-icon,
.sc-launcher .sc-closed-icon {
  bottom: 50px !important;
}

.sc-user-input--file-icon-wrapper {
  padding-top: 6px !important;
  cursor: pointer !important;
}

.inputRound {
  position: relative;
  background-color: #fff;
  display: flex;
  border-radius: 30px;
  width: 240px;
  height: 44px;
  align-items: center;

  label {
    // position: absolute;
    line-height: 44px;
    font-size: 14px;
    color: #009aaa;
    padding-left: 18px;
    white-space: nowrap;
    width: 58px;
  }

  input {
    border: none;
    background-color: transparent;
    padding-left: 15px;
  }

  .validateImg {
    width: 63px;
    height: 26px;
    // margin-top: 10px;
    padding-right: 5px;
  }

  .rightIcon {
    // position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding-right: 20px;

    img {
      width: 20px;
    }
  }
}

.validateWrap {
  input {
    width: 70px;
  }
}

.loginBtnWrap {
  // position: absolute;
  margin-top: 40px;
  bottom: 20px;
  position: relative;

  // p {
  //   position: absolute;
  //   top: 28%;
  //   transform: translateX(-50%);
  //   left: 50%;
  //   color: white;
  //   font-weight: bold;
  //   font-size: 16px;
  // }

  img {
    width: 242px;
  }
}

.numF {
  // font-family: Arial;
}

.chF {
  font-family: PingFangSC-Regular, sans-serif;
}

.overlayClass {
  z-index: 99999 !important;
}

.van-nav-bar .van-icon {
  color: black !important;
}
.van-nav-bar {
  background-color: rgb(241, 245, 254) !important;
}
.van-hairline--bottom::after {
  content: none !important;
}
.dropdown {
  margin-bottom: 15px;

  .van-dropdown-menu__bar {
    height: 35px;
    border-radius: 10px !important;
    position: relative;

    &:after {
      content: "";
      border: none;
      background-image: url("/icon_setting.png");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      right: 0;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      right: 20px;
    }

    .van-dropdown-menu__title {
      &:after {
        border: none;
      }
    }
  }
}

.timePick .form-control {
  border-radius: 10px !important;

  > svg {
    display: none;
  }
}

.teamReport {
  .timePick .form-control {
    border: none !important;
    height: 35px;
    line-height: 35px;
    padding: 0 10px 0 5px !important;
    font-size: 12px;

    > svg {
      display: none;
    }

    .dateDaultWrap {
      display: flex;
      align-items: center;

      img {
        margin-left: auto;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.van-cell__title {
  // font-size: 12px;
  // font-weight: normal;
}

.van-cell__value,
.van-cell__label {
  color: #999999 !important;
  font-size: 11px;
}

.van-nav-bar__left {
  img {
    width: 9px;
    height: 13px;
  }
}

.van-nav-bar__title {
  font-size: 14px !important;
}

.dateDrop {
  height: 28px;

  .van-dropdown-menu__bar {
    border-radius: 50px !important;
  }

  .van-dropdown-menu__title {
    margin-right: auto;
    margin-left: 15px;
  }
}

.hideScroll {
  overflow: hidden;
  height: 100vh;
}

.vue-simple-drawer {
  z-index: 9999 !important;
  width: 270px;
  background: #fff !important;
}

.vue-simple-drawer .close-btn .leftright,
.vue-simple-drawer .close-btn .rightleft {
  background-color: #333333 !important;
}

.vue-simple-drawer.right {
  top: 45px !important;
  padding: 0px !important;
}

.close-btn {
  z-index: 99 !important;
  top: 25px !important;
}

.vue-simple-drawer {
  color: #333333 !important;
}

.vue-simple-drawer .close-btn .leftright,
.vue-simple-drawer .close-btn .rightleft {
  height: 2px !important;
  width: 20px !important;
}

.van-picker {
  z-index: 99999;
}

.iosSelect {
  border-radius: 10px;
  background-color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 20px;
}

.teamReport .daterangepicker.openscenter {
  left: 70% !important;
}

// .lastCell.van-hairline--top-bottom::after,
// .lastCell.van-hairline-unset--top-bottom::after {
//   border-bottom: none !important;
//   content: none !important;
// }
// .lastCell {
//   &:after {
//   }
// }
.transDrop {
  .van-dropdown-menu__title::after {
    content: none;
  }

  .van-dropdown-menu__bar {
    box-shadow: none;
    background-color: transparent !important;
  }
}

.van-switch__node {
  top: -1.5px !important;
}

.sc-launcher {
  display: none !important;
}

.audio {
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.van-dialog__message {
  display: flex;
  flex-direction: column;
}

.van-dialog__message--has-title {
  padding-top: 18px !important;
}

.lockWrap {
  position: relative;

  .closeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.psmtc_lNiH {
  bottom: 50px !important;

  img {
    width: 45px !important;
    height: 45px !important;
  }
}

.checkModal {
  // height: 400px !important;
  // padding-bottom: 15px;
  .van-dialog__header {
    padding-bottom: 15px;
  }

  .checkBody {
    padding: 0 20px;
    height: 300px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .van-dialog__content {
    height: 100%;
  }

  .van-dialog {
    // height: 100% !important;
  }
}

.loginFalse {
  .van-dialog__header {
    color: red !important;
  }

  .van-button {
    color: black !important;
  }
}

.orderContentWrap {
  .van-cell__value {
    font-size: 14px;
    color: #525252 !important;
  }
}

.el-table .tableWarnning {
  background: oldlace;
}

.el-table th {
  background-color: rgb(150, 164, 184) !important;
  color: white;
}
.el-table {
  margin-top: 10px;
}

.van-dialog {
  background: linear-gradient(180deg, #b7c6ff 0%, #f0f0ff 157.69%);
  padding: 15px 10px 25px 10px;
  .van-dialog__content {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    padding-top: 8px;
  }
  .van-dialog__footer {
    border-radius: 0px 0px 10px 10px;
    padding: 15px 10px 20px 10px;
    background-color: #fff;
  }
  .van-dialog__cancel {
    background-color: #f1f5fe;
    margin-right: 5px;
    color: #b8b6b7;
  }
  .van-dialog__confirm {
    background-color: #4065f0;
    margin-left: 5px;
    color: white;
  }
  .van-dialog__cancel,
  .van-dialog__confirm {
    border-radius: 10px;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    // bc
  }
  .van-dialog__content {
    background-color: white;
  }
}
.van-dialog__header {
  padding-top: 0 !important;
  padding-bottom: 12px !important;
  color: #1d389f;
}
.van-field__control {
  color: #4361ff !important;
}

.d-center {
  display: flex;
  justify-content: center;
}

.teamReport {
  .reportrange-text {
    border: none !important;
    display: flex;
    align-items: center;
    height: 40px !important;
  }
}
.addInvitesDialog,
.bankcardDilog {
  overflow: unset !important;
}

.van-dialog__header {
  font-weight: bold !important;
}
.van-field__label {
  font-weight: bold;
}
</style>
