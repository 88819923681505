import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import VueCookie from "vue-cookie";
import axios from "axios";
import VueAxios from "vue-axios";
import { Toast } from "vant";
import VueClipboard from "vue-clipboard2";
import Chat from "vue-beautiful-chat";
import decimalPoint from "./filter/decimalPoint.js";
import thousands from "./filter/thousands.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import DrawerLayout from "vue-drawer-layout";
// import "babel-polyfill";
import * as imageConversion from "image-conversion";
import Contextmenu from "vue-contextmenujs";
import "@vant/touch-emulator";
import { Table, TableColumn } from "element-ui";
import "element-ui/lib/theme-chalk/table.css";
import "element-ui/lib/theme-chalk/table-column.css";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Contextmenu);

Vue.use(DrawerLayout);
Vue.use(Chat);
Vue.use(imageConversion);

Vue.use(VueClipboard);
Vue.use(Vant);
Vue.use(VueCookie);
Vue.use(VueAxios, axios);

// 小數點
Vue.filter("decimalPoint", decimalPoint);

Vue.filter("thousands", thousands);
// import "./assets/js/socketHandler";
import "./assets/js/returnColor";

import "./assets/js/axiosHandler";
import Embed from "v-video-embed";
import VueVideoPlayer from "vue-video-player";

// require videojs style
import "video.js/dist/video-js.css";

Vue.use(VueVideoPlayer);

Vue.use(Embed);
Vue.prototype.$successMeg = function () {
  Toast.success("完成");
};

Vue.prototype.$returnMarkName = function (name) {
  if (String(name).length == 2) {
    return String(name).substring(0, 1) + "*";
  } else {
    let mark = "";
    for (let i = 0; i < String(name).length - 2; i++) {
      mark += "*";
    }
    return (
      String(name).substring(0, 1) +
      mark +
      String(name).substring(String(name).length - 1)
    );
  }
};
Vue.prototype.$returnBuySel = function (name) {
  let returnName = "";
  // 买货卖货
  if (name == "进货") {
    if (document.domain == process.env.VUE_APP_TYH) {
      returnName = "买货";
    }
  }
  if (name == "出货") {
    if (document.domain == process.env.VUE_APP_TYH) {
      returnName = "卖货";
    }
  }
  if (name == "报表" || name == "流水") {
    if (document.domain == process.env.VUE_APP_TYH) {
      returnName = "明细";
    }
  }
  if (name == "进货") {
    if (document.domain == "43.138.153.196") {
      returnName = "买货";
    }
  }
  if (name == "出货") {
    if (document.domain == "43.138.153.196") {
      returnName = "卖货";
    }
  }
  if (name == "报表" || name == "流水") {
    if (document.domain == "43.138.153.196") {
      returnName = "明细";
    }
  }
  if (name == "进货") {
    if (document.domain == "ht.hdsaj.com") {
      returnName = "买货";
    }
  }
  if (name == "出货") {
    if (document.domain == "ht.hdsaj.com") {
      returnName = "卖货";
    }
  }
  if (name == "报表" || name == "流水") {
    if (document.domain == "ht.hdsaj.com") {
      returnName = "明细";
    }
  }
  if (name == "进货") {
    if (document.domain == "store.rzxl923.com") {
      returnName = "买货";
    }
  }
  if (name == "出货") {
    if (document.domain == "store.rzxl923.com") {
      returnName = "卖货";
    }
  }
  if (name == "报表" || name == "流水") {
    if (document.domain == "store.rzxl923.com") {
      returnName = "明细";
    }
  }
  if (returnName) {
    return returnName;
  } else {
    return name;
  }
};

Vue.prototype.$returnTimeR = function (date) {
  if (date == "") {
    return "";
  }
  let dateObj = new Date(date);

  let year = dateObj.getFullYear().toString().slice(-2);

  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let day = ("0" + dateObj.getDate()).slice(-2);

  let hours = ("0" + dateObj.getHours()).slice(-2);
  let minutes = ("0" + dateObj.getMinutes()).slice(-2);
  let seconds = ("0" + dateObj.getSeconds()).slice(-2);

  let outputDateStr = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

  return outputDateStr;
};

Vue.prototype.$returnTime = function (date, full) {
  if (date == "") {
    return "";
  }
  var d = new Date(String(date).replace(/-/g, "/")),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  let data = [year, month, day].join("-");
  if (full == "all") {
    return data + " " + [hour, minute].join(":");
  } else {
    return [year, month, day].join("-");
  }
};

// JSON比對
Vue.prototype.$deepEqual = function (obj1, obj2) {
  // 檢查類型是否相等
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // 檢查是否為基本類型
  if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // 獲取對象的屬性
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // 檢查屬性數量是否相等
  if (keys1.length !== keys2.length) {
    return false;
  }

  // 遞歸比較屬性和值
  for (const key of keys1) {
    if (!Vue.prototype.$deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
// Vue.config.devtools = true;
// Vue.config.devtools = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
