import axios from "axios";
export default {
  namespaced: true,
  state: {
    message: "",
    currentUserId: "",
    currentUser: "尚未選擇",
    messageList: [],
    isChatOpen: false,
    // 多久一次
    getTime: 30000,
    timeOut: "",
    messagePage: {},
    newMessagesCount: 0,
  },
  mutations: {
    SHOW(state, method) {
      state.isChatOpen = method;
    },
    MESSAGE(state, page) {
      // if (state.messageList.length) return;
      if (page == 1 || !page) {
        state.messageList = [];
      }
      axios.get(`/mobile/chat?page=${page || 1}`).then((res) => {
        if (res.data.code == 0) {
          state.messagePage = res.data;
          let messageList = [];
          let fileList = [
            ".jpg",
            ".jpeg",
            ".png",
            ".mp4",
            ".mov",
            ".avi",
            ".mpeg",
            ".quicktime",
          ];
          res.data.data.forEach((msg) => {
            let lastFile = false;
            fileList.forEach((e) => {
              if (
                msg.message.split("?")[0].indexOf(e) ==
                msg.message.split("?")[0].length - e.length
              ) {
                lastFile = true;
              }
            });
            if (msg) {
              if (msg.message.indexOf("http") == 0 && lastFile) {
                messageList.push({
                  reply_id: msg.reply_id,
                  id: msg.id,
                  created_at: msg.created_at,
                  type: "file",
                  author: msg.from == "会员" ? "me" : msg.from,
                  data: {
                    file: {
                      name: "档案",
                      url: `${msg.message}`,
                    },
                  },
                });
              } else {
                messageList.push({
                  reply_id: msg.reply_id,
                  id: msg.id,
                  created_at: msg.created_at,
                  type: "text",
                  author: msg.from == "会员" ? "me" : msg.from,
                  meta: "red",
                  data: {
                    text: msg.message,
                  },
                });
              }
            }
            if (msg.response) {
              if (msg.type == "text") {
                let data = {};
                data.text = msg.response;
                if (msg.response_http == 400) {
                  data.meta = "發送失敗";
                }
                messageList.push({
                  reply_id: msg.reply_id,
                  created_at: msg.created_at,
                  type: msg.type,
                  author: "self",
                  data,
                });
              } else {
                let data = { file: {} };
                data.file.url = `${msg.response}`;
                if (msg.response_http == 400) {
                  data.file.name = "發送失敗";
                }
                messageList.push({
                  reply_id: msg.reply_id,
                  created_at: msg.created_at,
                  type: "file",
                  author: "self",
                  data,
                });
              }
            }
          });
          state.messageList = messageList.sort((a, b) => a.id - b.id);
          this.dispatch("message/setReply");
          if (page == 1 || !page) {
            setTimeout(() => {
              document.querySelector(".chatContent").scrollTop =
                document.querySelector(".chatContent").scrollHeight;
            });
          }
        }
      });
    },
    PREMESSAGE(state, page) {
      let currentHeight = document.querySelector(".chatContent").scrollHeight;
      axios.get(`/mobile/chat?page=${page}`).then((res) => {
        if (res.data.code == 0) {
          state.messagePage = res.data;
          let messageList = [];
          let fileList = [
            ".jpg",
            ".jpeg",
            ".png",
            ".mp4",
            ".mov",
            ".avi",
            ".mpeg",
            ".quicktime",
          ];
          res.data.data.forEach((msg) => {
            let lastFile = false;
            fileList.forEach((e) => {
              if (msg.message.indexOf(e) == msg.message.length - e.length) {
                lastFile = true;
              }
            });
            if (msg) {
              if (msg.message.indexOf("http") == 0 && lastFile) {
                messageList.push({
                  reply_id: msg.reply_id,
                  created_at: msg.created_at,
                  id: msg.id,
                  type: "file",
                  author: msg.from == "会员" ? "me" : msg.from,
                  data: {
                    file: {
                      name: "档案",
                      url: `${msg.message}`,
                    },
                  },
                });
              } else {
                messageList.push({
                  reply_id: msg.reply_id,
                  created_at: msg.created_at,
                  id: msg.id,
                  type: "text",
                  author: msg.from == "会员" ? "me" : msg.from,
                  meta: "red",
                  data: {
                    text: msg.message,
                  },
                });
              }
            }
            if (msg.response) {
              if (msg.type == "text") {
                let data = {};
                data.text = msg.response;
                if (msg.response_http == 400) {
                  data.meta = "發送失敗";
                }
                messageList.push({
                  created_at: msg.created_at,
                  id: msg.id,
                  type: msg.type,
                  author: "self",
                  data,
                });
              } else {
                let data = { file: {} };
                data.file.url = `${msg.response}`;
                if (msg.response_http == 400) {
                  data.file.name = "發送失敗";
                }
                messageList.push({
                  created_at: msg.created_at,
                  id: msg.id,
                  type: "file",
                  author: "self",
                  data,
                });
              }
            }
          });
          let repeatMsgList = [];
          state.messageList.forEach((e) => {
            messageList.forEach((msg) => {
              if (e.id == msg.id) {
                repeatMsgList.push(e.id);
              }
            });
          });
          messageList.forEach((e) => {
            if (repeatMsgList.indexOf(e.id) == -1) {
              state.messageList.unshift(e);
            }
          });
          setTimeout(() => {
            document.querySelector(".chatContent").scrollTop =
              document.querySelector(".chatContent").scrollHeight -
              currentHeight;
          });
        }
      });
      state.messageList.sort((a, b) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      });
      this.dispatch("message/setReply");
    },
    PUSHMESSAGE(state, msg) {
      if (!state.messageList.length && !state.isChatOpen) return;
      let fileList = [
        ".jpg",
        ".jpeg",
        ".png",
        ".mp4",
        ".mov",
        ".avi",
        ".mpeg",
        ".quicktime",
      ];
      let lastFile = false;
      fileList.forEach((e) => {
        if (msg.message.indexOf(e) == msg.message.length - e.length) {
          lastFile = true;
        }
      });
      if (msg) {
        if (msg.message.indexOf("http") == 0 && lastFile) {
          state.messageList.push({
            reply_id: msg.reply_id,
            created_at: msg.created_at,
            id: msg.id,
            type: "file",
            author: msg.from == "会员" ? "me" : msg.from,
            data: {
              file: {
                name: "档案",
                url: `${msg.message}`,
              },
            },
          });
        } else {
          state.messageList.push({
            reply_id: msg.reply_id,
            created_at: msg.created_at,
            id: msg.id,
            type: "text",
            author: msg.from == "会员" ? "me" : msg.from,
            meta: "red",
            data: {
              text: msg.message,
            },
          });
        }
      }
      state.messageList = state.messageList.sort((a, b) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      });
      this.dispatch("message/setReply");
      if (document.querySelector(".chatContent")) {
        setTimeout(() => {
          document.querySelector(".chatContent").scrollTop =
            document.querySelector(".chatContent").scrollHeight;
        }, 100);
      }
    },
    SETUNREAD(state, num) {
      if (state.isChatOpen) {
        state.newMessagesCount = 0;
        return;
      }
      state.newMessagesCount = num;
    },
    SETREPLY(state) {
      let messageList = JSON.parse(JSON.stringify(state.messageList));
      messageList.forEach((e) => {
        if (e.reply_id && !e.replyContent) {
          axios.get(`/mobile/messages/${e.reply_id}`).then((res) => {
            e.replyContent = {
              from: res.data.data.from == "会员" ? "自己:" : "客服:",
              msg: res.data.data.message,
            };
            state.messageList = messageList.sort((a, b) => {
              return a.id - b.id;
            });
          });
        }
      });
    },
  },
  actions: {
    showMessageBox(context, method) {
      context.commit("SHOW", method);
      if (method) {
        context.commit("MESSAGE");
      }
    },
    getUserMessage(context) {
      context.commit("MESSAGE");
    },
    getPrePageMessage(context, page) {
      return context.commit("PREMESSAGE", page);
    },
    pushMsg(context, msg) {
      context.commit("PUSHMESSAGE", msg);
    },
    setUnreadNum(context, num) {
      context.commit("SETUNREAD", num);
    },
    setReply(context) {
      context.commit("SETREPLY");
    },
  },
};
